<template>
  <div :style="cssProps">
    <div id="welcome" class="section">
      <a name="welcome"></a>
      <v-row align="center" justify="center">
        <v-col cols="12" md="6" class="d-flex justify-center">
          <div id="screen">
            <img id="cloud_words" :src="'images/cloud_words_' + $i18n.locale + '.png'" alt="Tellae" />
            <!-- <cloud
              :data="$t('cloud_words')"
              :fontSizeMapper="fontSizeMapper"
              :onWordClick="wordClickHandler"
              :rotate="rotate"
              padding="5"
              width="600"
              height="600"
              :key="cloudKey"
            /> -->
          </div>
        </v-col>
        <v-col cols="12" md="6" class="flex-row justify-center">
          <div>
            <img id="bigLogo" src="images/logoTellae_v3.svg" alt="Tellae" />
            <h2 id="slogan">{{ $t("slogan") }}</h2>
          </div>
          <div id="short_company_description">
            <span v-html="$t('short_company_description')"></span>
          </div>
        </v-col>
      </v-row>
    </div>
    <!--
    <div
      id="company"
      class="section"
      style="background-image: linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,0.6)), url(images/nantes_map.png);"
    >
      <v-row align="center" justify="center">
        <div class="section_title">
          <h1><a name="company"></a>{{ $t("menu.company") }}</h1>
        </div>
        <div class="section_description">
          <div class="company_text">
            <p v-for="item in $t('company.paragraphs')" v-bind:key="item.text">
              <span v-html="item.text" :class="item.visible"></span>
            </p>
          </div>
        </div>
      </v-row>
    </div>
    -->
    <div id="services" class="section">
      <v-row align="center" justify="center">
        <div class="section_title">
          <h1><a name="services"></a>{{ $t("menu.services") }}</h1>
        </div>
        <div class="section_description">
          <p>
            {{ $t("services.description") }}
          </p>
        </div>
      </v-row>
      <img
        v-if="$vuetify.breakpoint.width >= 780"
        :src="'images/value_' + $i18n.locale + '.png'"
        alt="Tellae"
        style="height: 600px;"
      />

      <img
        v-if="$vuetify.breakpoint.width < 780"
        :src="'images/value_' + $i18n.locale + '_small.png'"
        alt="Tellae"
        style="width: 100%;"
      />
    </div>
    <div id="use_case" class="section">
      <div>
        <v-row align="center" justify="center">
          <div class="section_title">
            <h1><a name="use_case"></a>{{ $t("menu.use_case") }}</h1>
          </div>
          <div class="section_description">
            <p>
              {{ $t("use_case.description") }}
            </p>
          </div>
        </v-row>
        <uclist></uclist>
      </div>
    </div>
    <div id="products" class="section">
      <v-row align="center" justify="center">
        <div class="section_title">
          <h1><a name="products"></a>{{ $t("menu.products") }}</h1>
        </div>
        <div class="section_description">
          <p>
            {{ $t("products.description") }}
          </p>
        </div>
      </v-row>
      <products></products>
    </div>
    <div id="pricing" class="section">
      <v-row align="center" justify="center">
        <div class="section_title">
          <h1><a name="pricing"></a>{{ $t("menu.pricing") }}</h1>
        </div>
        <div class="section_description">
          <p>
            {{ $t("pricing.description") }}
          </p>
        </div>
      </v-row>
      <pricing></pricing>
    </div>
    <div id="team" class="section">
      <v-row align="start" justify="center">
        <div class="section_title">
          <h1><a name="team"></a>{{ $t("menu.team") }}</h1>
        </div>
        <div class="section_description">
          <p>
            {{ $t("team.description") }}
          </p>
        </div>
      </v-row>
      <v-row align="start" justify="center">
        <div class="box_team" v-for="member in team" v-bind:key="member.lastName">
          <team-member :id="member" />
        </div>
      </v-row>
    </div>

    <div id="news" class="section">
      <v-row align="center" justify="center">
        <div class="section_title">
          <h1><a name="news"></a>{{ $t("menu.news") }}</h1>
        </div>
        <div class="section_description">
          <p>
            <router-link :to="`/blog_full_list`">{{ $t("news.link") }}</router-link>
          </p>
        </div>
      </v-row>
      <blog-list></blog-list>
    </div>

    <div id="clients" class="clients">
      <v-row align="center" justify="center">
        <div class="section_title">
          <h1><a name="clients"></a>{{ $t("clients.title") }}</h1>
        </div>
      </v-row>
      <v-row align="center" justify="center">
        <div style="max-width:90%">
          <v-slide-group multiple show-arrows continuous>
            <v-slide-item v-for="client in clients" v-bind:key="client.img">
              <img :src="client.img" height="100px" style="padding: 20px" />
            </v-slide-item>
          </v-slide-group>
        </div>
      </v-row>
    </div>

    <div class="prefooter"></div>
    <div class="footerLogo"><img id="footerLogo" src="images/logoMain.svg" alt="Tellae" /></div>
    <div class="footer">
      <div>
        <div>
          <div class="social">
            <a href="mailto: contact@tellae.fr" target="_blank" title="contact@tellae.fr"
              ><awe-icon name="envelope" scale="2" label="Email" inverse
            /></a>
            <a href="https://www.linkedin.com/company/tellae/" target="_blank"
              ><awe-icon name="brands/linkedin" scale="2" label="LinkedIn" inverse
            /></a>
            <a href="https://github.com/tellae" target="_blank"
              ><awe-icon name="brands/github" scale="2" label="GitHub" inverse
            /></a>
          </div>
          <language />
        </div>
        <div class="partners">
          <h4>{{ $t("footer.partners") }}</h4>
          <a href="https://www.id4mobility.org/membres/tellae" target="_blank">ID4Mobility</a><br />
          <a href="https://www.images-et-reseaux.com/adherent/tellae/" target="_blank">Images et Réseaux</a><br />
        </div>
        <div class="address">
          <a href="https://goo.gl/maps/KzxAhubQzS9KdaCq5" target="_blank"
            ><awe-icon name="map-marked-alt" scale="2" label="Map" inverse
          /></a>
          <b>Tellae</b><br />
          1 rue Jean Simon Voruz<br />
          44200 Nantes<br />
          France<br />
          <a href="mailto:contact@tellae.fr" style="color:white">contact@tellae.fr</a>
        </div>
      </div>
      <span>Copyright &copy; 2020-{{ year }}</span>
    </div>
  </div>
</template>

<script>
import "vue-awesome/icons/brands/github";
import "vue-awesome/icons/brands/linkedin";
import "vue-awesome/icons/envelope";
import "vue-awesome/icons/map-marked-alt";
import TeamMember from "./TeamMember.vue";
import Products from "./Products.vue";
import Pricing from "./Pricing.vue";
import Services from "./Services.vue";
import Language from "./Language.vue";
import UseCase from "./UseCase.vue";
import BlogList from "./BlogList.vue";
import Cloud from "vue-d3-cloud";
import Uclist from "./UseCaseList.vue";

function rotation(w) {
  let value = w.value * 0 + Math.random();
  let angle = 90;
  if (value < 0.5) {
    angle = 0;
  }
  return angle;
}

/* eslint-disable vue/no-unused-components */
export default {
  name: "Main",
  data: () => ({
    year: new Date().getFullYear(),
    cloudKey: 0, // key for cloud word update
    fontSizeMapper: word => Math.log10(word.value) * 10 * (1 + (Math.random() - 0.5) * 0.2),
    rotate: word => rotation(word) * 0,
    clients: [
      { img: "images/clients/Logo_Cerema_Horizontal.svg.png" },
      { img: "images/clients/800px-Logo_Kicéo.svg.png" },
      { img: "images/clients/langfr-1920px-IdFMobilités.svg.png" },
      { img: "images/clients/logo_ile de france.png" },
      { img: "images/clients/langfr-1920px-Keolis_2017_logo.svg.png" },
      { img: "images/clients/Actu_2018_saint-malo-mat.png" },
      { img: "images/clients/1920px-Logo_Métropole_Grand_Nancy_-_2016.svg.png" },
      { img: "images/clients/Logo_CC_Pays_d'Opale.jpg" }, // Pays d'Opale
      { img: "images/clients/Logo_202209_pdl.png" },
      { img: "images/clients/PNG_RVB_RATP_GROUP.png" }, // RATP GROUP
      { img: "images/clients/RENNES_Métropole_noir.svg.png" },
      { img: "images/clients/Logo_CC_Sarrebourg_Moselle_Sud.png" }, // Sarrebourg
      { img: "images/clients/1280px-CASE_Logo_2018.png" }, // Seine Eure
      { img: "images/clients/transdev.png" }
    ]
  }),
  created: () => {},
  mounted() {
    // automatic cloud word update
    let vm = this;
    window.setInterval(function() {
      vm.cloudKey = vm.cloudKey + 1;
    }, 60000);

    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
    });
  },
  components: {
    TeamMember,
    Products,
    Pricing,
    Services,
    Language,
    UseCase,
    BlogList,
    Cloud,
    Uclist
  },
  methods: {
    switchLanguage: function(locale) {
      this.$i18n.locale = locale;
    },
    switchToFr: function() {
      this.switchLanguage("fr");
    },
    switchToEn: function() {
      this.switchLanguage("en");
    },
    wordClickHandler() {
      return null;
    }
  },
  computed: {
    cssProps() {
      return {
        "--primary-color": this.$vuetify.theme.themes.light.primary,
        "--secondary-color": this.$vuetify.theme.themes.light.secondary
      };
    },
    team() {
      let team = this.$t("team.teamMembers");
      return Object.keys(team).sort((a, b) => a.localeCompare(b));
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#bigLogo {
  height: 250px;
  max-width: 40vw;
}

.section {
  min-height: calc(100vh);
}

#welcome {
  min-height: calc(100vh - 56px);
}

#outro-gradient {
  height: 30vh;
  background: linear-gradient(180deg, #62a2bc7f 30%, #ffffff 100%);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.box_team {
  width: 20vw;
  max-width: 250px;
  height: 400px;
  margin: 20px;
}

.box_use_case {
  width: 600px;
  height: 300px;
}

#mobility div {
  color: white;
  padding: 20px;
}

#welcome h2 {
  color: var(--secondary-color);
  font-size: 28px;
  text-align: center;
}

.prefooter {
  border-radius: 50% / 100px 100px 0px 0px;
  background-color: var(--primary-color);
  height: 10px;
}

#team {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

#team .box {
  margin: 10px;
  opacity: 1;
}

.section_title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.section_title h1 {
  color: var(--primary-color);
  font-size: 50px;
  font-weight: bold;
  border-bottom: solid 4px var(--primary-color);
  padding: 70px 0 5px 0;
}

.section_description {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px;
  max-width: 700px;
}

.section_description > p {
  color: var(--primary-color);
  font-size: 18px;
  font-weight: bold;
  text-align: justify;
  padding: 0 20px 0 20px;
}

.footer {
  background-color: var(--primary-color);
  min-height: 100px;
  padding: 30px;
  padding-bottom: 10px;
  color: #fff;
}

.footer > div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
}

.v-theme-default .footer a:not(.v-button) {
  color: #fff;
}
.address {
  text-align: left;
}

.address svg {
  margin-right: 10px;
}

.social {
  display: flex;
  align-items: center;
}

.footer a {
  margin: 5px;
  transition: opacity 300ms;
}

.footer a:hover {
  opacity: 0.5;
}

.partners h4 {
  margin: 5px;
}

.use_case_item > h2 {
  color: #fff;
  border-radius: 10px;
  max-width: 40vh;
  min-height: 10vh;
  padding: 10px;
}

.partners > a {
  color: white;
  text-decoration: none;
}

#domain_usage_title > h1 {
  color: var(--secondary-color);
}

.domain_usage > h2 {
  color: var(--secondary-color);
  min-height: 10vh;
  padding: 5px;
}

#use_case {
  border-radius: 100% 0px 0px 100% / 200px;
  padding-top: 20px;
  padding-bottom: 10px;
  border-top: 6px solid #3d6482;
  border-bottom: 6px solid #3d6482;
}

#use_case > div {
  background-color: #3d6482;
  border-radius: 100% 0px 0px 100% / 200px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 180px;
  min-height: 100vh;
}

#use_case .section_description > p,
#use_case h1 {
  color: white;
}

#products {
  background-color: #62a3bc3f;
  border-radius: 100% / 200px 0px 200px 0px;
  padding-bottom: 70px;
  color: white;
}

#services {
  border-radius: 100% 0px 100% / 100px 0px;
  padding-bottom: 100px;
}

.prefooter {
  margin-top: 10vh;
}

.footerLogo {
  background: transparent;
  position: absolute;
  bottom: calc(200px - 10vh);
  right: calc(200px + 5vh);
}

#footerLogo {
  height: 15vh;
}

@media (max-width: 780px) {
  .footer {
    padding-left: 0px;
    padding-right: 0px;
  }

  .footer > div {
    flex-direction: row;
  }

  .footer > div > div {
    margin: 10px;
  }

  .social {
    flex-direction: row-reverse;
  }

  .footerLogo {
    background: var(--primary-color);
    position: inherit;
    width: 100%;
    height: 5vh;
  }

  #footerLogo {
    margin-top: -5vh;
  }

  #screen {
    display: none;
  }

  #short_company_description {
    /* display: none; */
  }

  .section_description > p {
    font-size: 16px;
  }

  #bigLogo {
    max-width: 70vw;
  }

  .box_team {
    width: 90vw;
  }
}

#welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  /*flex-direction: column;*/
}

#short_company_description {
  padding: 15px;
  max-width: 450px;
  text-align: justify;
  margin: auto;
  margin-top: 30px;
}

#company {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
}

.company_text {
  max-width: 800px;
  border-radius: 20px;
  background-color: var(--primary-color);
  color: #fff;
  text-align: justify;
  padding: 15px;
  margin: 15px 25px 25px 25px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

#screen {
  margin: auto;
}
</style>
