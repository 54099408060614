<template>
  <div>
    <v-row align="center" justify="center">
      <div class="box_use_case" v-for="usecase in usecases" v-bind:key="usecase.title">
        <v-hover v-slot="{ hover }">
          <v-card
            hover
            rounded="xl"
            class="box_card_use_case"
            :class="{ 'on-hover': hover }"
            :elevation="hover ? 12 : 2"
          >
            <v-card-title class="mb-n2">
              <div class="v-title2">
                <v-icon color="secondary" left x-large>
                  {{ usecase.icon }}
                </v-icon>
                <span v-html="usecase.title"></span>
              </div>
            </v-card-title>
            <v-card-text>
              <div class="v-text2">
                {{ usecase.text }}
              </div>
            </v-card-text>
          </v-card>
        </v-hover>
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "UseCaseList",
  components: {},
  props: {},
  data: () => ({}),
  computed: {
    usecases() {
      return this.$t("use_case.list");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.box_use_case {
  margin: 10px;
  padding: 0px;
}

.box_card_use_case {
  height: 220px;
  max-width: 450px;
  min-width: 450px;
}

.box_use_case:hover .v-title2 {
  color: var(--secondary-color) !important;
}

.v-title2 {
  font-weight: bold;
  text-align: left;
  font-size: 18px;
  word-break: normal;
  color: var(--primary-color);
  line-height: 130%;
}

.v-text2 {
  text-align: justify;
  font-size: 14px;
}

@media (max-width: 780px) {
  .box_card_use_case {
    height: 100%;
    max-width: 350px;
    min-width: 350px;
  }
  .v-title2 {
    font-size: 18px;
  }
}
</style>
