<template>
  <v-hover v-slot="{ hover }">
    <v-card min-height="220px" :class="{ 'on-hover': hover }" :elevation="hover ? 12 : 2">
      <v-card-title>
        <router-link :to="`/blog/fr/kite_guides_step_by_step`">Titre</router-link>
      </v-card-title>
    </v-card>
  </v-hover>
</template>

<script>
import moment from "moment";

export default {
  name: "Blog",
  data: () => ({}),
  computed: {
    title: function() {
      let text = this.content.title;
      const max_length = 80;
      if (text.length > max_length) {
        text = text.substr(0, max_length) + "...";
      }
      return text;
    },
    date: function() {
      moment.locale(this.content.language);
      let d = moment(this.content.date, "YYYY-MM-DD");
      return d.format("LL");
    },
    author: function() {
      let text = this.content.author;
      const max_length = 30;
      if (text.length > max_length) {
        text = text.substr(0, max_length) + "...";
      }
      return text;
    }
  },
  props: {
    content: Object
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
.v-image {
  background-size: cover;
  border-radius: 3px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.v-title {
  font-weight: bold;
  word-break: normal;
  text-align: left !important;
}
.v-subhead {
  color: #999;
  text-align: left !important;
}
a {
  color: #42b983;
}
</style>
