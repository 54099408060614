<template>
  <div>
    <v-row align="center" justify="center">
      <div class="box_blog" v-for="info in blog" v-bind:key="info.date">
        <blog :content="info" />
      </div>
    </v-row>
  </div>
</template>

<script>
import blogs from "../blogs.js";
import Blog from "./Blog.vue";

export default {
  name: "BlogList",
  components: {
    Blog
  },
  props: {
    forceAll: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    blog() {
      const { locale } = this.$i18n;
      // Display at least 3 blogs  and more if screen is big enough
      // Sort by decreasing date
      // TODO: Add a resize listener to adjust this value on resize
      const blogWidth = 300;
      let output = (blogs || []).sort((a, b) => (a.date > b.date ? -1 : 1)).filter(b => b.language === locale);
      if (!this.forceAll) {
        output = output.slice(0, Math.max(window.innerWidth / blogWidth, 3));
      }

      return output;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.box_blog {
  width: 500px;
  margin: 20px;
}

@media (max-width: 780px) {
  .box_blog {
    width: 350px;
  }
}
</style>
