<template>
  <div>
    <v-row align="start" justify="center">
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-center"
        v-for="service in $t('services.items')"
        v-bind:key="service.name"
      >
        <div class="description">
          <h1>{{ service.name }}</h1>
          <p class="main_desc">
            {{ service.main_description }}
          </p>
          <p class="content hidden-sm-and-down" v-for="paragraph in service.paragraphs" v-bind:key="paragraph">
            <span v-html="paragraph"></span>
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.component("services", {
  components: {},
  props: [],
  data: function() {
    return {};
  },
  mounted() {},
  methods: {}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  font-size: 40px;
  font-weight: bold;
}

.main_desc {
  font-size: 18px;
  text-align: justify;
  font-weight: bold;
}

.content {
  font-size: 16px;
  text-align: justify;
}

.description {
  max-width: 750px;
  padding: 0 20px 0 20px;
  color: #3d6482;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  min-height: 400px;
}

.illustration {
  padding: 0 60px 0 60px;
}

img {
  max-width: 200px;
}

v-row {
  padding: 10px;
}

@media (max-width: 780px) {
  .description {
    min-height: 200px;
  }
}
</style>
