<template>
  <div>
    <v-row align="end" justify="center">
      <v-card max-width="1300px" outlined color="transparent">
        <v-row align="end">
          <v-col md="6" class="justify-center">
            <div class="product">
              <div class="product_description">
                <h1>
                  <img class="product_logo" :src="$t('products.items[1].logo')" width="50px" />{{
                    $t("products.items[1].name")
                  }}
                </h1>
                <!--
            <p class="main_desc">
              <span v-html="$t('products.items[1].main_description')"></span>
            </p>
            -->
                <p></p>
                <p class="content" v-for="paragraph in $t('products.items[1].paragraphs')" v-bind:key="paragraph">
                  <span v-html="paragraph"></span>
                </p>
              </div>
              <div class="illustration">
                <iframe
                  width="445"
                  height="250"
                  src="https://www.youtube.com/embed/4pM1iLNFuVw?autoplay=1&mute=1&&playlist=4pM1iLNFuVw&loop=1&controls=0&allowfullscreen=0"
                  frameborder="0"
                  rel="0"
                  allowFullScreen="true"
                >
                </iframe>
              </div>
            </div>
          </v-col>
          <v-col md="6" sm="12" class="justify-center">
            <div class="product">
              <div class="product_description">
                <v-card width="100%">
                  <div class="values pa-3">
                    <div v-for="value in $t('products.items[1].values')" v-bind:key="value">
                      <v-row class="align-center">
                        <v-col cols="2">
                          <v-icon class="value-icon" x-large color="secondary">
                            {{ value.icon }}
                          </v-icon></v-col
                        >
                        <v-col cols="10">
                          <span>
                            {{ value.text }}
                          </span></v-col
                        >
                      </v-row>
                    </div>
                  </div>

                  <!-- 
              <v-expansion-panels hover accordion :multiple="multiple" :value="opened">
                <v-expansion-panel v-for="(functionality, index) in $t('products.functionalities')" :key="index">
                  <v-expansion-panel-header disable-icon-rotate>
                    <template v-slot:actions>
                      <v-icon class="icon" x-large color="secondary">
                        {{ functionality.icon }}
                      </v-icon>
                    </template>
                    <h3 class="header">{{ functionality.title }}</h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ul>
                      <li class="content" v-for="fct in functionality.functions" v-bind:key="fct">{{ fct }}</li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels> -->
                </v-card>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.component("Products", {
  components: {},
  props: [],
  data: function() {
    return {};
  },
  computed: {
    multiple() {
      if (this.$vuetify.breakpoint.width >= 780) {
        return true;
      } else {
        return false;
      }
    },
    opened() {
      if (this.$vuetify.breakpoint.width >= 780) {
        return [0, 1, 2, 3];
      } else {
        return [null];
      }
    }
  },
  mounted() {},
  methods: {}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.icon {
  order: 0;
}

.header {
  order: 1;
  margin-left: 10px;
  color: var(--primary-color);
}

.content {
  color: var(--primary-color);
}

.values {
  font-size: 18px;
  font-weight: bold;
  text-align: start;
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  max-width: 450px;
  background-color: white;
  color: var(--primary-color);
}

.value-icon {
  margin-left: 10px;
}

h1 {
  font-size: 65px;
  font-weight: bold;
  text-align: start;
}

.product {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product_logo {
  margin: 0 20px 0 0;
}

.main_desc {
  font-size: 20px;
  text-align: justify;
  font-weight: bold;
}

.content {
  font-size: 16px;
  text-align: left;
}

.product_description {
  max-width: 750px;
  color: #3d6482;
  padding: 0 60px 0 60px;
}

.illustration > img {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  width: 500px;
}

@media (max-width: 780px) {
  .description {
    padding: 0 10px 0 10px !important;
  }
  .illustration > iframe {
    width: 350px;
    height: 195px;
  }
  .product_description {
    max-width: 400px;
  }
  .value-icon {
    margin-left: 5px;
  }
  .values {
    /*display: none;*/
    font-size: 14px;
  }
  .header {
    font-size: 14px;
    margin-left: 5px;
  }
  .content {
    font-size: 14px;
  }
}

/* Removes default style of bullet point */
/*
.product_description li {
  list-style: none;
}
*/
/* ::before creates a pseudo-element that is the first child of the selected element */
/*
.product_description li::before {
  content: "\00BB";
}
*/
.v-expansion-panel-content {
  text-align: start;
}
</style>
