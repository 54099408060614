<template>
  <div>
    <h1>Page introuvable</h1>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
  props: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
