const MESSAGES = {
  en: {
    menu: {
      company: "Company",
      use_case: "Use case",
      products: "Platform",
      services: "Services",
      blog: "Blog",
      news: "News",
      team: "Team",
      pricing: "Pricing",
      language: "Language",
      doc: "KITE doc",
      tooltip: {
        "kite": "Open KITE application"
      }
    },
    cloud_words: [
      { text: "Decision support tool", value: 100000000 },
      { text: "Simulation", value: 100000000 },
      { text: "Data analysis", value: 100000000 },
      { text: "Public transport", value: 10000 },
      { text: "Shared mobility", value: 10000 },
      { text: "Intermodality", value: 10000 },
      { text: "Multimodality", value: 10000 },
      { text: "Consulting", value: 1000 },
      { text: "Platform", value: 1000 },
      { text: "Prediction", value: 100 },
      { text: "Anticipation", value: 100 },
      { text: "Observation", value: 100 },
      { text: "Understanding", value: 100 },
      { text: "Optimisation", value: 100 }
    ],
    clients: {"title": "They trust us"},
    news: {
      link: "View full list"
    },
    company: {
      paragraphs: [
        {
          visible: "",
          text:
            "The Tellae company was created in July 2020, in the midst of the COVID-19 pandemic,\
            following an alarming observation: <b>decision support tools and consulting methodologies\
            are not up to the new and future challenges of the cities</b>."
        },
        {
          visible: "hidden-sm-and-down",
          text:
            'For Vincent Leblond, CEO of Tellae, "The current health and economic crisis is\
            disrupting the paradigms of territorial management. In the years to come,\
            climate change could have much more noticeable effects. In the face of these\
            major upheavals, <b>I cannot imagine that public authorities are suffering when they must\
            be able to understand their city, evaluate scenarios and anticipate probable futures in order to better prepare for them</b>.\
            Private stakeholders, for their part, must be able to offer solutions and adapt\
            their business models".'
        },
        {
          visible: "hidden-sm-and-down",
          text:
            "The founders of Tellae quickly assembled a high-level team. <b>During nearly a year\
            of Research and Development, Tellae engineers developed and experimented with new\
            decision support tools and methodologies.</b> In collaboration with research laboratories,\
            the state of the art of AI is exploited to its full potential and in all its diversity:\
            data science, machine learning, complex systems and optimization. A visualisation and\
            simulation platform has emerged.\
            This platform makes it possible to go further in the \
            visualization and analysis of mobility. It also makes it possible to evaluate \
            scenarios of mobility services."
        },
        {
          visible: "",
          text:
            "<b>In spring 2021, Tellae begins marketing its consulting services augmented\
            by its visualisation and simulation platform.</b> Tellae's ambition is to provide\
            tailor-made consulting to its customers\
            in the field of transport: passenger mobility, urban logistics and waste management.\
            Our services are intended for both public authorities and private companies."
        }
      ]
    },
    use_case: {
      description:
        "Tellae works on use cases promoting the development of public transport\
        and complementary mobility such as transport on demand, shared mobility\
        solutions, cycling and walking. Tellae provides its solutions in the\
        planning and strategic design phase, as well as in the operational management\
        phase of mobility services.",
      list: [
        {
          title: "Optimize the quality of service of public transport lines",
          icon: "directions_bus",
          text:
            "Public transport networks face the challenges of improving the\
            quality of service and rationalizing public expenditure. We help\
            you meet these challenges, in upstream planning or in network\
            operation assistance."
        },
        {
          title: "Plan and validate the multimodal design of the public transport network",
          icon: "commute",
          text:
            "Public transport networks are at the heart of the development\
            of mobility that respects environmental and societal issues. We\
            help you with planning assistance and validation of multimodal\
            design choices for public transport networks."
        },
        {
          title: "Design and confirm the design of on-demand public transport",
          icon: "emoji_people",
          text:
            "On-demand public transport makes it possible to extend\
            public transport to complement or replace regular lines.\
            However, these are expensive services. We help you design\
            and confirm the design of the service, in line with the\
            mobility needs of the territory."
        },
        {
          title: "Evaluate shared mobility solutions (bike sharing, car sharing,\
              carpooling)",
          icon: "bike_scooter",
          text:
            "Shared mobility solutions offer potential for changing\
            mobility practices, with a reduction in car dependency\
            and a complement to public transport. We help you assess\
            their impact on mobility practices and establish optimal sizing."
        },
        {
          title: "Fine-tune the practices of mobility and the potential\
              for evolution",
          icon: "directions_walk",
          text:
            "Mobility practices must evolve in order to meet climate,\
            societal and economic challenges. Using a very detailed\
            observation of the mobility of your territory, we help you\
            to identify the potential for the evolution of mobility practices."
        },
        {
          title: "Identify the levers of action for the development\
            of the practice of cycling",
          icon: "directions_bike",
          text:
            "The bicycle is a great tool for developing carbon-free\
            mobility. However, many obstacles limit its progress.\
            We help you identify the brakes and levers through the\
            development of public space. We also help you finely\
            quantify the demand potential."
        }
      ],
      items: [
        {
          title: "Observe and understand with data",
          paragraphs: [
            "In recent years, we have observed a real awareness \
            the importance of data to better observe, understand and respond \
            respond to questions from stakeholders in cities.",
            "However, the step towards putting it into practice is still too rarely taken. \
            There are several reasons for this: lack of in-house technical skills \
            and even within traditional consulting firms, while firms \
            data specialists lack business expertise.",
            "Drawing on the experience of our managers, we are developing algorithms and \
            methodologies dedicated to transport and mobility issues.\
            <ul> \
            <li>We are thus exploiting the undervalued potential of Open Data but \
            also business data from our customers. </li> \
            <li>We allow our clients to better observe and understand their problem; \
            through the production of tailor-made analyzes and visualizations.</li> \
            <li>We rely on AI algorithms to merge data \
            in order to obtain new, more complete and more precise data.</li> \
            <li>We use our solution <i>Kite</i> to deliver \
            interactive visualization so that our clients embrace the results.</li> \
            </ul>"
          ]
        },
        {
          title: "Foresee and predict with simulation",
          paragraphs: [
            "Managing cities and mobility services requires anticipating and planning. \
            Yet the stakeholders in the cities are little or poorly equipped to anticipate the \
            probable futures and thus prepare for them. The expert opinion remains far too often \
            standard. However, it does not take into account all the complexity of the cities.",
            "Cities stakeholders have sometimes equipped themselves with decision-making tools. \
            Fortunately, these tools meet certain needs. However, these tools \
            often lack versatility and are far too inert. It is too often \
            necessary to develop craft patches.",
            "For almost a year now, we have been developing predictive approaches combining the \
            best of the state of the art: machine learning and agent-based simulation.\
            <ul>\
            <li>We implement the most suitable algorithms and methodologies \
            to the problems of our customers. </li> \
            <li>We offer our customers access to <i> a digital twin </i>. This is a \
            simulation environment relating to the subject studied.</li> \
            <li>We do scripted forecasts of the \
            changes in strategy or operational management.</li> \
            <li>Our clients derive very detailed results that inform their decisions.</li> \
            <ul>"
          ]
        }
      ]
    },
    services: {
      description:
        "Using our platform of analysis and simulation, we offer consulting services to Public Transport\
        Authorities and Mobility Operators"
    },
    pricing: {
      description: "",
      type: [
        {
            "name": "Free",
            "description": "Access basic functions and easily try KITE",
            "action": {
              "text": "Create an account",
              "href": "https://kite.tellae.fr",
            },
            "comment": "Create an account then use KITE immediately",
            "details": [
              "Limited catalog of reference data",
              "Limited viewing and storage of your data",
              "Analysis of flows and transport networks"
            ]
        },
        {
            "name": "Standard",
            "description": "Benefit from all of KITE’s features",
            "action": {
              "text": "Ask for a quote",
              "href": "mailto:contact@tellae.fr?subject=Quote KITE standard",
            },
            "comment": "Contact us for pricing for your organization",
            "details": [
              "Unlimited number of projects",
              "Collaboration around projects",
              "Extended storage of your project data",
              "Catalogue of reference data for the whole of France",
              "Calculation of territorial indicators",
              "Flow databases",
              "Advanced analyzes of public transport flows and networks",
              "Simulation of mobility services",
            ]
        },
        {
            "name": "Business +",
            "description": "Integrate KITE into your data management tools",
            "action": {
              "text": "Ask for a quote",
              "href": "mailto:contact@tellae.fr?subject=Quote KITE business",
            },
            "comment": "Contact us to discuss your needs and obtain a quote",
            "details": [
              "All standard offering",
              "Customized visualization functions",
              "Customized calculation functions",
              "Connecting to your databases",
              "Direct access to data from your visualization applications",
              "Access to processing functions from your calculation applications",
              "Access to simulation functions from your dedicated software"
            ]
        },
        {
            "name": "Public sector",
            "description": "Benefit from free access to the standard offer",
            "action": {
              "text": "Contact us",
              "href": "mailto:contact@tellae.fr?subject=Access to KITE public sector",
            },
            "comment": "Contact us to activate your free access",
            "details": [
              "Unlimited number of projects",
              "Collaboration around projects",
              "Extended storage of your project data",
              "Catalogue of reference data for the whole of France",
              "Calculation of territorial indicators",
              "Flow databases",
              "Advanced analyzes of public transport flows and networks",
              "Simulation of mobility services",
            ]
        }
      ]
    },
    products: {
      description:
        "KITE is a SaaS mobility analysis and simulation platform.\
        It is designed for professionals to easily access data\
        territorial with analysis and visualization functionalities.\
        It also provides simulation functionalities to design\
        and optimize mobility offers and services.",
      items: [
        {
          name: "Starling",
          main_description:
            "Create a digital twin of flows and mobility services of your city\
            at the level of individuals and vehicles.",
          paragraphs: [
            "Starling is an agent based simulator offering great versality\
            and very detailed predictive modeling. The simulator framework is distributed in Open Source\
            in order to benefit the scientific and research community."
          ],
          img: "images/products/kite_trace.png",
          url: "https://github.com/tellae/starling",
          logo: "images/products/logo-starling.svg"
        },
        {
          name: "Kite",
          main_description:
            "<ul><li> Analyse flows and mobility services of your city</li>\
            <li> Design and evaluate scenarios with simulation</li></ul>",
            values: [
              {icon: "storage", text: "Access all mobility data in one tool"},
              {icon: "verified", text: "Benefit from the most recent reference data"},
              {icon: "insights", text: "Easily create maps and advanced calculations"},
              {icon: "draw", text: "Start by analyzing then simulating your mobility projects"}],
          paragraphs: [
            "If you want to try the platform, go to\
            <a href='https://kite.tellae.fr' target='_blank'>https://kite.tellae.fr</a>.",
            "For reading the documentation, go to\
            <a href='/#/blog/en/kite_guides_step_by_step'>Documentation</a>."
          ],
          img: "images/products/kite_flow.png",
          url: "https://kite.tellae.fr",
          logo: "images/products/logo-kite.png"
        }
      ],
      functionalities: [
        {
          title: "Explore and map territories",
          icon: "map",
          functions: [
            "Access to a rich catalog of reference data\
            throughout France (Open Data, INSEE, SIRENE, etc.)",
            "Calculation of indicators making it possible to characterize\
            the territory, mobility offers and socio-demography"
          ]
        },
        {
          title: "Observe travel practices",
          icon: "search",
          functions: [
            "Quick access to flow databases\
            (INSEE Home-Work, Register of Proof of\
            carpooling, ...)",
            "Features for viewing and exploring\
            flows (filters, statistics, spatial aggregation)"
          ]
        },
        {
          title: "Analyse mobility networks and services",
          icon: "commute",
          functions: [
            "Importing TC networks in GTFS format",
            "Analysis of public transport networks\
            (network indicators, calculation of isochrones,\
            coverage calculation, ...)",
            "Simulation of the creation of a new\
            bus line, coverage and cost assessment"
          ]
        },
        {
          title: "Design and optimize offer scenarios",
          icon: "design_services",
          functions: [
            "Assessing the potential of a new service\
            of mobility and optimization of the existing",
            "Visualization of data and results of\
            simulations carried out with open software\
            source Starling"
          ]
        }
      ]
    },
    team: {
      description:
        "Our team brings together engineers passionate about the cities\
        and their challenges. We believe that scientific approaches to decision support\
        are powerfull tools for a sustainable and managed development\
        of cities.",
      teamMembers: {
        vleblond: {
          firstName: "Vincent",
          lastName: "Leblond",
          bio: "",
          title: "CEO",
          linkedin: "https://www.linkedin.com/in/vleblond/",
          github: "vincent-leblond"
        },
        ldesbureaux: {
          firstName: "Léo",
          lastName: "Desbureaux",
          bio: "",
          title: "Simulation & optimisation",
          github: "leo-desbureaux-tellae"
        },
        rcattiau: {
          firstName: "Rémi",
          lastName: "Cattiau",
          bio: "",
          title: "Software & systems expert",
          linkedin: "https://www.linkedin.com/in/loopingz/",
          github: "loopingz"
        },
        mlaurent: {
          firstName: "Marie",
          lastName: "Laurent",
          bio: "",
          title: "Datascience",
          linkedin: "https://www.linkedin.com/in/marie-mc-laurent/",
          github: "MarieMcLaurent"
        }
      }
    },
    footer: {
      partners: "Our partners"
    },
    slogan: "Solutions for mobility analysis and simulation",
    short_company_description:
      "Tellae is an innovative consulting firm specialized\
      in cities transportation issues.\
      We provide our clients, both public and private, with solutions\
      to support your strategic and operational decisions."
  },
  fr: {
    menu: {
      company: "Société",
      use_case: "Cas d'usage",
      products: "Plateforme",
      services: "Services",
      blog: "Blog",
      news: "Actualités",
      team: "Équipe",
      pricing: "Tarifs",
      language: "Langue",
      doc: "KITE doc",
      tooltip: {
        "kite": "Ouvrir l'application KITE"
      }
    },
    cloud_words: [
      { text: "Aide à la décision", value: 1000000 },
      { text: "Simulation", value: 1000000 },
      { text: "Analyse de données", value: 1000000 },
      { text: "Transport public", value: 1000 },
      { text: "Mobilité partagée", value: 1000 },
      { text: "Intermodalité", value: 1000 },
      { text: "Multimodalité", value: 1000 },
      { text: "Conseils", value: 100 },
      { text: "Plateforme", value: 100 },
      { text: "Prévision", value: 50 },
      { text: "Anticipation", value: 50 },
      { text: "Observation", value: 50 },
      { text: "Compréhension", value: 50 },
      { text: "Optimisation", value: 50 }
    ],
    clients: {"title": "Ils nous font confiance"},
    news: {
      link: "Voir la liste complète"
    },
    company: {
      paragraphs: [
        {
          visible: "",
          text:
            "La société Tellae a été créée en juillet 2020, en pleine pandémie de COVID-19,\
            suite à un constat alarmant : <b>les outils d'aide à la décision et les méthodologies\
            de conseil ne sont pas à la hauteur des enjeux nouveaux et futurs des territoires</b>."
        },
        {
          visible: "hidden-sm-and-down",
          text:
            'Pour Vincent Leblond, CEO de Tellae, "La crise sanitaire et économique actuelle\
            bouleverse les paradigmes de gestion des territoires. Dans les années à venir, le\
            changement climatique pourrait avoir des effets bien plus notables. Face à ces\
            grands bouleversements, <b>je ne conçois pas que les collectivités territoriales subissent\
            les évènements alors qu’elles doivent pouvoir mieux comprendre leur territoire,\
            mais également évaluer des scénarios\
            et anticiper les futurs probables pour mieux s’y préparer</b>.\
            Les acteurs privés doivent quant à eux pouvoir proposer des solutions innovantes et adapter\
            leurs business modèles".'
        },
        {
          visible: "hidden-sm-and-down",
          text:
            "Les fondateurs de Tellae ont ainsi rapidement monté une équipe de haut niveau.\
            <b>Pendant près d’un an de Recherche et Développement, les ingénieurs de Tellae ont\
            développé et expérimenté de nouveaux outils et méthodologies d’aide à la décision.</b>\
            En lien avec des laboratoires de recherche, l’état de l’art de l’IA est exploité à\
            son plein potentiel et dans toute sa diversité : data science, machine learning,\
            système complexe et optimisation. Il en est sorti une plateforme de visualisation et\
            de simulation de la mobilité. Cette plateforme permet d'aller plus loin dans la\
            visualisation et l'analyse de la mobilité. Elle permet également d'évaluer des\
            scénarios d'évolution des services de mobilité."
        },
        {
          visible: "",
          text:
            "<b>Au printemps 2021, Tellae démarre la commercialisation de ses prestations\
            augmentées par sa plateforme de visualisation et de simulation.\
            Tellae intervient en tant\
            que cabinet de conseil augmenté par l'IA en problématiques de transports des territoires.\
            </b> L’ambition de Tellae est de\
            fournir des conseils sur-mesure à ses clients dans le domaine du transport : mobilité\
            des personnes, logistique urbaine et gestion des déchets.\
            Nos prestations s’adressent\
            à la fois aux collectivités territoriales mais également aux entreprises privées."
        }
      ]
    },
    use_case: {
      description:
        "Tellae intervient sur des cas d'usages favorisant le développement des transports\
        publics et des mobilités complémentaires telles que le transport à la demande,\
        les solutions de mobilité partagées, le vélo et la marche. Tellae fournit ses solutions\
        en phase de planification et de conception stratégique, ainsi qu'en phase\
        de gestion opérationnelle des services de mobilité.",
      list: [
        {
          title: "Optimiser la qualité de service des lignes\
            de transport public",
          icon: "directions_bus",
          text:
            "Les réseaux de transports publics font face à des enjeux\
            d'amélioration de la qualité de service et de rationalisation\
            des dépenses publiques. Nous vous aidons à répondre à ces enjeux,\
            en planification amont ou en aide à l'exploitation des réseaux."
        },
        {
          title: "Planifier et valider le design multimodal du réseau de transport public",
          icon: "commute",
          text:
            "Les réseaux de transports publics sont au cœur du développement\
            d'une mobilité respectueuse des enjeux environnementaux et sociétaux.\
            Nous vous aidons avec une assistance à la planification\
            et la validation des choix de design multimodal des réseaux de\
            transport public."
        },
        {
          title: "Concevoir et confirmer le design de services de\
            transport à la demande TAD",
          icon: "emoji_people",
          text:
            "Le TAD permet d'étendre le transport public en complément ou en\
            remplacement des lignes régulières. Il s'agit toutefois de services\
            coûteux. Nous vous aidons à concevoir et confirmer le design du\
            service, en lien avec les besoins de mobilité du territoire."
        },
        {
          title: "Évaluer les solutions de mobilité partagée\
            (VLS, autopartage, covoiturage)",
          icon: "bike_scooter",
          text:
            "Les solutions de mobilité partagée offrent un potentiel d'évolution\
            des pratiques de mobilité, avec une réduction de la dépendance à la voiture\
            et un complément aux transports publics. Nous vous aidons à évaluer leur\
            impact sur les pratiques de mobilité et à établir un dimensionnement\
            optimal."
        },
        {
          title: "Appréhender finement les pratiques de mobilité\
            et le potentiel d'évolution",
          icon: "directions_walk",
          text:
            "Les pratiques de mobilité doivent évoluer afin de répondre aux\
            enjeux climatiques, sociétaux et économiques. À l'aide d'une\
            observation très détaillée de la mobilité de votre territoire,\
            nous vous aidons à identifier le potentiel d'évolution des\
            pratiques de mobilité."
        },
        {
          title: "Identifier les leviers d'actions pour le développement\
            de la pratique du vélo",
          icon: "directions_bike",
          text:
            "Le vélo est un formidable outil de développement d'une mobilité\
            décarbonée. De nombreux freins en limitent toutefois la progression.\
            Nous vous aidons à identifier les freins et leviers\
            par les aménagements de l'espace public. Nous vous aidons également\
            à quantifier finement le potentiel de demande."
        }
      ],
      items: [
        {
          title: "Observer et comprendre avec les données",
          paragraphs: [
            "Depuis quelques années, nous observons une réelle prise de conscience\
            de l'importance des données pour mieux observer, comprendre et répondre\
            aux questionnements des acteurs des territoires.",
            "Pourtant, le pas vers la mise en pratique est encore trop rarement franchi.\
            Il y a plusieurs raisons à cela: absence de compétences techniques en interne\
            et même au sein des cabinets de conseils traditionnels, alors que les cabinets\
            spécialistes des données manquent d'expertises métiers.",
            "Fort de l'expérience de nos dirigeants, nous développons des algorithmes et\
            des méthodologies dédiées aux problématiques des transports et de la mobilité.\
            <ul>\
            <li>Nous exploitons ainsi le potentiel sous valorisé des données Open Data mais\
            également des données métiers de nos clients.</li>\
            <li>Nous permettons à nos clients de mieux observer et comprendre leur problématique;\
            par la production d'analyses et de visualisations sur-mesure.</li>\
            <li>Nous nous appuyons sur des algorithmes IA pour croiser et fusionner les données\
            afin d'en obtenir de nouvelles données plus complètes et plus précises.</li>\
            <li>Nous utilisons notre solution de visualisation <i>Kite</i> pour livrer des restitutions\
            interactives afin que nos clients s'approprient les résultats.</li>\
            </ul>"
          ]
        },
        {
          title: "Anticiper et prévoir avec la simulation",
          paragraphs: [
            "Gérer des villes et des services de mobilité nécessite d'anticiper et de prévoir.\
            Pourtant les acteurs des territoires sont peu ou mal équipés pour anticiper les\
            futurs probables et ainsi s'y préparer. Le dire d'expert reste bien trop souvent\
            la norme. Or il ne permet pas de prendre en compte toute la complexité des territoires.",
            "Les acteurs des territoires se sont parfois équipés d'outils d'aide à la décision.\
            Fort heureusement ces outils répondent à certains besoins. Toutefois ces outils\
            manquent bien souvent de polyvalence et sont bien trop inertes. Il est trop souvent\
            nécessaire de développer des patchs artisanaux.",
            "Depuis bientôt un an, nous développons des approches prédictives combinant le\
            meilleur de l'état de l'art : machine learning et simulation multi-agent.\
            <ul>\
            <li>Nous mettons en oeuvre les algorithmes et les méthodologies les plus adaptées\
            aux problèmes de nos clients.</li>\
            <li>Nous offrons à nos clients un accès à <i>un jumeau numérique</i>. Il s'agit d'un\
            environnement de simulation portant sur le sujet étudié.</li>\
            <li>Nous réalisons des prévisions scénarisées des\
            changements de stratégie ou de gestion opérationnelle.</li>\
            <li>Nos clients en tirent des résultats très détaillés qui éclairent leurs décisions.</li>\
            <ul>"
          ]
        }
      ]
    },
    services: {
      description:
        "À l'aide de notre plateforme d'analyse et de simulation, nous offrons des services de conseil\
        auprès des Autorités Organisatrices des Mobilités et des Opérateurs de mobilités."
    },
    products: {
      description:
        "KITE est une plateforme SaaS d'analyse et de simulation de la mobilité.\
        Elle est conçue pour que les professionnels accèdent facilement aux données\
        territoriales avec des fonctionnalités d'analyse et de visualisation.\
        Elle fournit également des fonctionnalités de simulation aﬁn de concevoir\
        et optimiser les offres et services de mobilité.",
      items: [
        {
          name: "Starling",
          main_description:
            "Créez un jumeau numérique des flux et des réseaux de transport de votre territoire\
            à l'échelle des individus et des véhicules.",
          paragraphs: [
            "Starling repose sur l'approche multi-agent offrant une très grande polyvalence\
            et une modélisation prédictive très détaillée. Le framework du simulateur\
            est diffusé en Open Source afin d'en faire bénéficier à la communauté scientifique."
          ],
          img: "images/products/kite-trace-carpooling.png",
          url: "https://github.com/tellae/starling",
          logo: "images/products/logo-starling.svg"
        },
        {
          name: "Kite",
          main_description:
            "<ul><li> Analysez les flux et les services de mobilité de votre territoire</li>\
            <li> Concevez et évaluez des scénarios par la simulation</li></ul>",
          values: [
            {icon: "storage", text: "Accédez à toutes les données de mobilité dans un seul outil"},
            {icon: "verified", text: "Bénéficiez des données de référence les plus récentes"},
            {icon: "insights", text: "Réalisez facilement des cartes et des calculs avancés"},
            {icon: "draw", text: "Commencez par analyser puis simulez vos projets de mobilité"}],
          paragraphs: [
            "Pour essayer la plateforme, rendez-vous sur\
            <a href='https://kite.tellae.fr' target='_blank'>https://kite.tellae.fr</a>.",
            "Pour consulter la documentation, rendez-vous sur\
            <a href='/#/blog/fr/kite_guides_step_by_step'>Documentation</a>.",
          ],
          img: "images/products/kite-od-carpooling.png",
          url: "https://kite.tellae.fr",
          logo: "images/products/logo-kite.png"
        }
      ],
      functionalities: [
        {
          title: "Explorer et cartographier les territoires",
          icon: "map",
          functions: [
            "Accès à un riche catalogue de données de référence\
            sur l'ensemble de la France (Open Data, INSEE, SIRENE, ...)",
            "Calcul des indicateurs permettant de caractériser\
            le territoire, les oﬀres de mobilité et la socio-démographie"
          ]
        },
        {
          title: "Observer les pratiques de déplacements",
          icon: "search",
          functions: [
            "Accès rapide à des bases de données de ﬂux\
            (Domicile-Travail INSEE, Registre de Preuve de\
            covoiturage, ...)",
            "Fonctionnalités pour visualiser et explorer les\
            flux (filtres, statistiques, agrégation spatiale)"
          ]
        },
        {
          title: "Analyser les réseaux et les services de mobilité",
          icon: "commute",
          functions: [
            "Importation de réseaux TC au format GTFS",
            "Analyse des réseaux de transports publics\
            (indicateurs du réseau, calcul d'isochrones,\
            calcul de couverture, ...)",
            "Simulation de la création d'une nouvelle ligne de\
            bus, évaluation de la couverture et des coûts"
          ]
        },
        {
          title: "Concevoir et optimiser les scénarios d'offre",
          icon: "design_services",
          functions: [
            "Évaluation du potentiel d'un nouveau service\
            de mobilité et optimisation de l'existant",
            "Visualisation des données et des résultats de\
            simulations eﬀectuées avec le logiciel open\
            source Starling"
          ]
        }
      ]
    },
    pricing: {
      description: "",
      type: [
        {
            "name": "Gratuit",
            "description": "Accédez aux fonctions de base et essayer facilement KITE",
            "action": {
              "text": "Créer un compte",
              "href": "https://kite.tellae.fr",
            },
            "comment": "Créez un compte puis utilisez KITE immédiatement",
            "details": [
              "Catalogue limité de données de référence",
              "Visualisation et stockage limité de vos données",
              "Analyse des flux et des réseaux de transports"
            ]
        },
        {
            "name": "Standard",
            "description": "Bénéficiez de l'ensemble des fonctionnalités de KITE",
            "action": {
              "text": "Demander un devis",
              "href": "mailto:contact@tellae.fr?subject=Devis KITE standard",
            },
            "comment": "Contactez-nous pour connaître les tarifs pour votre organisation",
            "details": [
              "Nombre illimité de projets",
              "Collaboration autour des projets",
              "Stockage étendu de vos données projet",
              "Catalogue de données de référence sur l'ensemble de la France",
              "Calcul d'indicateurs territoriaux",
              "Bases de données de flux",
              "Analyses avancées des flux et des réseaux de transports publics",
              "Simulation de services de mobilité",
            ]
        },
        {
            "name": "Business +",
            "description": "Intégrez KITE dans vos outils de gestion des données",
            "action": {
              "text": "Demander un devis",
              "href": "mailto:contact@tellae.fr?subject=Devis KITE business",
            },
            "comment": "Contactez-nous pour échanger sur votre besoin et obtenir un devis",
            "details": [
              "Toute l'offre standard",
              "Fonctions de visualisation sur-mesure",
              "Fonctions de calcul sur-mesure",
              "Connection à vos bases de données",
              "Accès direct aux données depuis vos applications de visualisation",
              "Accès aux fonctions de traitements depuis vos applications de calculs",
              "Accès aux fonctions de simulation depuis vos logiciels dédiés"
            ]
        },
        {
            "name": "Secteur public",
            "description": "Bénéficiez d'un accès gratuit à l'offre standard",
            "action": {
              "text": "Contactez-nous",
              "href": "mailto:contact@tellae.fr?subject=Accès KITE secteur public",
            },
            "comment": "Contactez-nous pour activer votre accès gratuit",
            "details": [
              "Nombre illimité de projets",
              "Collaboration autour des projets",
              "Stockage étendu de vos données projet",
              "Catalogue de données de référence sur l'ensemble de la France",
              "Calcul d'indicateurs territoriaux",
              "Bases de données de flux",
              "Analyses avancées des flux et des réseaux de transports publics",
              "Simulation de services de mobilité",
            ]
        }
      ]
    },
    team: {
      description:
        "Notre équipe réunit des ingénieurs passionnés par les territoires\
        et leurs enjeux. Nous pensons que les approches scientifiques d'aide à la décision\
        sont des outils puissants pour un développement durable et maîtrisé\
        des territoires.",
      teamMembers: {
        vleblond: {
          firstName: "Vincent",
          lastName: "Leblond",
          bio: "",
          title: "CEO",
          linkedin: "https://www.linkedin.com/in/vleblond/",
          github: "vincent-leblond"
        },
        ldesbureaux: {
          firstName: "Léo",
          lastName: "Desbureaux",
          bio: "",
          title: "Simulation et optimisation",
          github: "leo-desbureaux-tellae"
        },
        rcattiau: {
          firstName: "Rémi",
          lastName: "Cattiau",
          bio: "",
          title: "Expert logiciel et systèmes",
          linkedin: "https://www.linkedin.com/in/loopingz/",
          github: "loopingz"
        },
        mlaurent: {
          firstName: "Marie",
          lastName: "Laurent",
          bio: "",
          title: "Datascience",
          linkedin: "https://www.linkedin.com/in/marie-mc-laurent/",
          github: "MarieMcLaurent"
        }
      }
    },
    footer: {
      partners: "Nos partenaires"
    },
    slogan: "Solutions d'analyse et de simulation de la mobilité",
    short_company_description:
      "Tellae est un cabinet de conseil innovant spécialisé\
      dans les problématiques de transports et mobilité des territoires.\
      Nous fournissons à nos clients, publics ou privés, des solutions pour éclairer\
      leurs décisions stratégiques et opérationnelles."
  }
};

export { MESSAGES };
