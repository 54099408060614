<template>
  <div>
    <v-row align="start" justify="center">
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-center"
        v-for="use_case in $t('use_case.items')"
        v-bind:key="use_case.name"
      >
        <div class="description">
          <h1>{{ use_case.title }}</h1>
          <p class="content" v-for="paragraph in use_case.paragraphs" v-bind:key="paragraph">
            <span v-html="paragraph"></span>
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.component("usecase", {
  components: {},
  props: [],
  data: function() {
    return {};
  },
  mounted() {},
  methods: {}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  font-size: 34px;
  font-weight: bold;
  color: white;
}

.main_desc {
  font-size: 18px;
  text-align: justify;
  font-weight: bold;
  color: white;
}

.content {
  font-size: 16px;
  text-align: justify;
  color: white;
}

.description {
  max-width: 750px;
  padding: 0 20px 0 20px;
  color: white;
}

.illustration {
  padding: 0 60px 0 60px;
}

img {
  max-width: 200px;
}

v-row {
  padding: 10px;
}
</style>
