<template>
  <v-app>
    <v-app-bar app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title style="width: 150px" class="ml-0 pl-4">
        <router-link to="/welcome"
          ><img src="images/logoTellae_v3.svg" alt="Tellae" style="height: 32px;"
        /></router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="hidden-sm-and-down">
        <v-tabs background-color="#F5F5F5" color="primary" optional>
          <!-- <v-tab to="/company">{{ $t("menu.company") }}</v-tab> -->
          <v-tab to="/services">{{ $t("menu.services") }}</v-tab>
          <v-tab to="/use_case">{{ $t("menu.use_case") }}</v-tab>
          <v-tab to="/products">{{ $t("menu.products") }}</v-tab>
          <v-tab to="/pricing">{{ $t("menu.pricing") }}</v-tab>
          <v-tab to="/team">{{ $t("menu.team") }}</v-tab>
          <v-tab to="/news">{{ $t("menu.news") }}</v-tab>
          <v-tab :to="doc_route"
            ><img src="images/logo-kite.png" style="height: 24px;margin-right: 5px;" />{{ $t("menu.doc") }}</v-tab
          >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-tab href="https://kite.tellae.fr" target="_blank" v-on="on">
                <img src="images/logo-kite.png" style="height: 24px;margin-right: 5px;" />KITE<v-icon small
                  >mdi-open-in-new</v-icon
                >
              </v-tab>
            </template>
            <span>{{ $t("menu.tooltip.kite") }}</span>
          </v-tooltip>
        </v-tabs>
      </div>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed>
      <div>
        <img src="images/logoTellae_v3.svg" alt="Tellae" style="height: 32px;" />
        <v-list nav dense>
          <v-list-item-group>
            <!--
            <v-list-item to="/company">
              <v-list-item-title>{{ $t("menu.company") }}</v-list-item-title>
            </v-list-item>
            -->
            <v-list-item to="/services">
              <v-list-item-title>{{ $t("menu.services") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/use_case">
              <v-list-item-title>{{ $t("menu.use_case") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/products">
              <v-list-item-title>{{ $t("menu.products") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/pricing">
              <v-list-item-title>{{ $t("menu.pricing") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/team">
              <v-list-item-title>{{ $t("menu.team") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/news">
              <v-list-item-title>{{ $t("menu.news") }}</v-list-item-title>
            </v-list-item>
            <v-list-item :to="doc_route">
              <v-list-item-title
                ><img src="images/logo-kite.png" style="height: 18px;margin-right: 5px;" />{{
                  $t("menu.doc")
                }}</v-list-item-title
              >
            </v-list-item>
            <v-list-item href="https://kite.tellae.fr" target="_blank">
              <v-list-item-title
                ><img src="images/logo-kite.png" style="height: 18px;margin-right: 5px;" />KITE<v-icon small
                  >mdi-open-in-new</v-icon
                ></v-list-item-title
              >
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
      <language style="margin-bottom: 20px;" />
    </v-navigation-drawer>
    <!-- Sizes your content based upon application components -->
    <v-main>
      <v-container fluid id="mainContainer"><router-view></router-view></v-container>
    </v-main>
  </v-app>
</template>

<script>
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Language from "./components/Language.vue";

gsap.registerPlugin(ScrollTrigger);

// TODO Check if we could optimize depending on the page
const initScroll = app => {
  let current;

  return () => {
    const menuAnimation = id => {
      const moveTo = () => {
        if (current) {
          clearTimeout(current);
        }
        // Avoid navigating to current route
        if (app.$route.name !== `Main_${id}`) {
          current = setTimeout(() => {
            if (app.$route.name.startsWith("Main")) {
              app.$router.replace({ name: `Main_${id}`, params: { id, scrollDriven: true } });
            }
            current = undefined;
          }, 300);
        }
      };
      ScrollTrigger.create({
        onEnter: moveTo,
        onEnterBack: moveTo,
        trigger: `#${id}`,
        markers: false,
        start: "top+=10 center",
        end: "bottom-=50 center",
        scrub: true
      });
    };
    ["pricing", "products", "team", "services", "use_case", "news", "welcome", "company"].forEach(menuAnimation);
    gsap.to(".domain_usage h2", {
      scrollTrigger: {
        trigger: ".domain_usage h2",
        start: "center bottom",
        scrub: true
      },
      stagger: 0.8,
      fontSize: "30",
      duration: 1
    });
  };
};
export default {
  name: "App",
  data: () => ({
    menuVisible: false,
    year: new Date().getFullYear(),
    drawer: false
  }),
  computed: {
    doc_route() {
      return "/blog/" + this.$i18n.locale + "/kite_guides_step_by_step";
    }
  },
  created: function() {
    setTimeout(initScroll(this), 200);
  },
  components: {
    Language
  }
};
</script>

<style lang="scss">
#mainContainer {
  padding: 0px;
}

.v-main {
  padding: 0px !important;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

// We should be able to avoid using important
.v-app-bar__nav-icon {
  display: none !important;
}

.v-navigation-drawer {
  padding-top: 20px;
}

.v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 959px) {
  .v-app-bar__nav-icon {
    display: block !important;
  }
}

html,
body {
  font-family: "Open Sans", sans-serif;
}

#app {
  font-family: "Open Sans", sans-serif;
}
</style>
