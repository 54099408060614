<template>
  <v-hover v-slot="{ hover }">
    <v-card :class="{ 'on-hover': hover }" :elevation="hover ? 12 : 2">
      <v-card-title>
        <div class="title">
          <div class="v-title">{{ user.firstName }}</div>
        </div>
      </v-card-title>

      <v-img style="cursor: pointer;" @click="openProfile" :src="image" position="top center"></v-img>

      <v-card-text>
        {{ user.bio }}
      </v-card-text>

      <v-card-actions>
        <div class="social">
          <a v-if="user.email !== undefined" :href="user.emailLink" target="_blank" :title="user.email"
            ><awe-icon name="envelope" scale="2" label="Email"
          /></a>
          <a v-if="user.linkedin !== undefined" :href="user.linkedin" target="_blank"
            ><awe-icon name="brands/linkedin" scale="2" label="LinkedIn"
          /></a>
          <a v-if="user.github !== undefined" :href="user.githubLink" target="_blank"
            ><awe-icon name="brands/github" scale="2" label="GitHub"
          /></a>
        </div>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "TeamMember",
  computed: {
    team() {
      return this.$t("team.teamMembers");
    },
    user: function() {
      const user = this.team[this.id];
      return {
        ...user,
        githubLink: `https://github.com/${user.github}`
      };
    },
    image: function() {
      return `/images/team/${this.id}.png`;
    },
    fullName: function() {
      return `${this.team[this.id].firstName} ${this.team[this.id].lastName}`;
    }
  },
  methods: {
    openProfile: function() {
      if (this.user.linkedin) {
        window.open(this.user.linkedin, "_blank");
      } else if (this.user.githubLink) {
        window.open(this.user.githubLink, "_blank");
      }
    }
  },
  props: {
    id: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
.v-image {
  height: 268px;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  /*background-blend-mode: luminosity;*/
  background-color: #b2d1dc;
  background-size: cover;
  transition: filter 1s;
}

.v-card:hover > .v-image {
  filter: grayscale(10%);
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.v-title {
  font-weight: bold;
  text-align: left;
}
.v-subhead {
  color: #999;
  font-weight: normal;
}

a {
  transition: color 300ms;
  color: var(--primary-color);
}

a:hover {
  color: #62a2bc !important;
}

.social > a {
  margin: 5px;
}
</style>
