import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#3d6482",
        secondary: "#84c186"
      },
      dark: {
        primary: "#3d6482",
        secondary: "#84c186"
      }
    }
  }
});
