<template>
  <div>
    <h1>{{ $t("menu.news") }}</h1>
    <blog-list :forceAll="true"></blog-list>
  </div>
</template>

<script>
import BlogList from "./BlogList.vue";

export default {
  name: "BlogFullList",
  components: {
    BlogList
  },
  props: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  padding-top: 15px;
}
</style>
