<template>
  <v-hover v-slot="{ hover }">
    <div class="blog-element">
      <router-link :to="`/blog/${content.id}`">
        <v-card min-height="220px" :class="{ 'on-hover': hover }" :elevation="hover ? 12 : 2">
          <v-card-title>
            <v-img
              :src="content.image"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="80px"
              class="white--text align-end"
            >
            </v-img>
            <div>
              <div class="v-title">
                <router-link :to="`/blog/${content.id}`">{{ title }}</router-link>
              </div>
              <div class="v-subhead">{{ date }}, {{ author }}</div>
            </div>
          </v-card-title>
        </v-card>
      </router-link>
    </div>
  </v-hover>
</template>

<script>
import moment from "moment";

export default {
  name: "Blog",
  data: () => ({}),
  computed: {
    title: function() {
      let text = this.content.title;
      const max_length = 80;
      if (text.length > max_length) {
        text = text.substr(0, max_length) + "...";
      }
      return text;
    },
    date: function() {
      moment.locale(this.content.language);
      let d = moment(this.content.date, "YYYY-MM-DD");
      return d.format("LL");
    },
    author: function() {
      let text = this.content.author;
      const max_length = 30;
      if (text.length > max_length) {
        text = text.substr(0, max_length) + "...";
      }
      return text;
    }
  },
  props: {
    content: Object
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
.v-image {
  background-size: cover;
  border-radius: 3px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.v-title {
  font-weight: bold;
  word-break: normal;
  text-align: left !important;
}
.blog-element a {
  text-decoration: none;
}
.v-subhead {
  color: #999;
  text-align: left !important;
}
a {
  color: #42b983;
}
</style>
