import Vue from "vue";
import App from "./App.vue";
import VueI18n from "vue-i18n";
import { BootstrapVue } from "bootstrap-vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import Icon from "vue-awesome/components/Icon";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { MESSAGES } from "./langtext.js";
import VueMarkdown from "vue-markdown";

Vue.use(Vuetify);
Vue.use(VueMarkdown);
Vue.use(VueI18n);
Vue.component("awe-icon", Icon);

// detect language (en as default or fr)
let lang = "en";
for (let i in navigator.languages) {
  if (navigator.languages[i].startsWith("en")) {
    break;
  }
  if (navigator.languages[i].startsWith("fr")) {
    lang = "fr";
    break;
  }
}

const messages = MESSAGES;
const i18n = new VueI18n({
  locale: lang,
  messages
});

Vue.config.productionTip = false;
Vue.use(BootstrapVue);

new Vue({
  i18n,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
