<template>
  <div class="language">
    <h4>{{ $t("menu.language") }}</h4>
    <label @click="switchToFr">fr</label>
    |
    <label @click="switchToEn">en</label>
  </div>
</template>

<script>
export default {
  name: "Language",
  methods: {
    switchLanguage: function(locale) {
      this.$i18n.locale = locale;
    },
    switchToFr: function() {
      this.switchLanguage("fr");
    },
    switchToEn: function() {
      this.switchLanguage("en");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
  cursor: pointer;
  transition: opacity 300ms;
}

label:hover {
  opacity: 0.5;
}
</style>
