import { render, staticRenderFns } from "./Blog.vue?vue&type=template&id=08853951&scoped=true"
import script from "./Blog.vue?vue&type=script&lang=js"
export * from "./Blog.vue?vue&type=script&lang=js"
import style0 from "./Blog.vue?vue&type=style&index=0&id=08853951&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.aspect_rules_js/vue-loader@15.11.1_-872804761/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08853951",
  null
  
)

export default component.exports