<template>
  <div>
    <v-row align="center" justify="center">
      <v-card width="80%" elevation="0" max-width="1200px">
        <v-row>
          <v-col md="3" v-for="formula in $t('pricing.type')" v-bind:key="formula">
            <v-card outlined>
              <v-card-title>{{ formula.name }}</v-card-title>
              <v-card-subtitle>{{ formula.description }}</v-card-subtitle>
              <v-card-action>
                <v-btn color="primary"
                  ><a :href="formula.action.href" style="color:white;text-decoration-line: none;">{{
                    formula.action.text
                  }}</a></v-btn
                >
                <br />
                <v-card-subtitle><span v-html="formula.comment"></span></v-card-subtitle>
              </v-card-action>
              <v-divider></v-divider>
              <v-card-text>
                <ul v-for="element in formula.details" v-bind:key="element">
                  <li>{{ element }}</li>
                </ul></v-card-text
              >
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.component("Pricing", {
  components: {},
  props: [],
  data: function() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-card__subtitle {
  text-align: left;
}

.v-card__text {
  text-align: left;
}

ul {
  padding-left: 10px;
}

li {
  position: relative;
  padding-left: 0em; /* space to preserve indentation on wrap */
}

/*
ul li:before {
  content: "\2714\0020";
  color: var(--secondary-color);
  padding-right: 5px;
  position: absolute;
  left: 0;
}
*/

span {
  font-size: 12px;
  line-height: 3px;
}
</style>
